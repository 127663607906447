const EMAIL_TEMPLATE_REQUEST = 'EMAIL_TEMPLATE_REQUEST';
const EMAIL_TEMPLATE_SUCCESS = 'EMAIL_TEMPLATE_SUCCESS';
const EMAIL_TEMPLATE_FAILURE = 'EMAIL_TEMPLATE_FAILURE';
const SAVE_TEMPLATE_REQUEST = 'SAVE_TEMPLATE_REQUEST';
const SAVE_TEMPLATE_SUCCESS = 'SAVE_TEMPLATE_SUCCESS';
const SAVE_TEMPLATE_FAILURE = 'SAVE_TEMPLATE_FAILURE';
const SET_EMAIL_TEMPLATE_SOURCE = 'SET_EMAIL_TEMPLATE_SOURCE';
const CURRENT_FROM_TEMPLATE = 'CURRENT_FROM_TEMPLATE';
const SET_EMAIL_ADDRESS = 'SET_EMAIL_ADDRESS';

const OFFER_ASSIGNMENT_EMAIL_SUBJECT_LIMIT = 1000;
const EMAIL_TEMPLATE_FIELD_MAX_LIMIT = 50000;
const EMAIL_TEMPLATE_SOURCE_NEW_EMAIL = 'new_email';
const EMAIL_TEMPLATE_SOURCE_FROM_TEMPLATE = 'from_template';

const EMAIL_TEMPLATE_SUBJECT_KEY = 'email-template-subject';

export {
  EMAIL_TEMPLATE_REQUEST,
  EMAIL_TEMPLATE_SUCCESS,
  EMAIL_TEMPLATE_FAILURE,
  SAVE_TEMPLATE_REQUEST,
  SAVE_TEMPLATE_SUCCESS,
  SAVE_TEMPLATE_FAILURE,
  OFFER_ASSIGNMENT_EMAIL_SUBJECT_LIMIT,
  EMAIL_TEMPLATE_FIELD_MAX_LIMIT,
  SET_EMAIL_TEMPLATE_SOURCE,
  EMAIL_TEMPLATE_SOURCE_NEW_EMAIL,
  EMAIL_TEMPLATE_SOURCE_FROM_TEMPLATE,
  CURRENT_FROM_TEMPLATE,
  SET_EMAIL_ADDRESS,
  EMAIL_TEMPLATE_SUBJECT_KEY,
};
