const PAGINATION_REQUEST = 'PAGINATION_REQUEST';
const PAGINATION_SUCCESS = 'PAGINATION_SUCCESS';
const PAGINATION_FAILURE = 'PAGINATION_FAILURE';
const SORT_REQUEST = 'SORT_REQUEST';
const SORT_SUCCESS = 'SORT_SUCCESS';
const SORT_FAILURE = 'SORT_FAILURE';
const CLEAR_TABLE = 'CLEAR_TABLE';

export {
  PAGINATION_REQUEST,
  PAGINATION_SUCCESS,
  PAGINATION_FAILURE,
  SORT_REQUEST,
  SORT_SUCCESS,
  SORT_FAILURE,
  CLEAR_TABLE,
};
