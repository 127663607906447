const FETCH_DASHBOARD_ANALYTICS_REQUEST = 'FETCH_DASHBOARD_ANALYTICS_REQUEST';
const FETCH_DASHBOARD_ANALYTICS_SUCCESS = 'FETCH_DASHBOARD_ANALYTICS_SUCCESS';
const FETCH_DASHBOARD_ANALYTICS_FAILURE = 'FETCH_DASHBOARD_ANALYTICS_FAILURE';
const CLEAR_DASHBOARD_ANALYTICS = 'CLEAR_DASHBOARD_ANALYTICS';

export {
  FETCH_DASHBOARD_ANALYTICS_REQUEST,
  FETCH_DASHBOARD_ANALYTICS_SUCCESS,
  FETCH_DASHBOARD_ANALYTICS_FAILURE,
  CLEAR_DASHBOARD_ANALYTICS,
};
