const FETCH_DASHBOARD_INSIGHTS_REQUEST = 'FETCH_DASHBOARD_INSIGHTS_REQUEST';
const FETCH_DASHBOARD_INSIGHTS_SUCCESS = 'FETCH_DASHBOARD_INSIGHTS_SUCCESS';
const FETCH_DASHBOARD_INSIGHTS_FAILURE = 'FETCH_DASHBOARD_INSIGHTS_FAILURE';
const CLEAR_DASHBOARD_INSIGHTS = 'CLEAR_DASHBOARD_INSIGHTS';

export {
  FETCH_DASHBOARD_INSIGHTS_REQUEST,
  FETCH_DASHBOARD_INSIGHTS_SUCCESS,
  FETCH_DASHBOARD_INSIGHTS_FAILURE,
  CLEAR_DASHBOARD_INSIGHTS,
};
